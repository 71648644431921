<template>
  <div id='wrapper'>
    <overlay />
    <admin-nav-bar v-if='admin' />
    <div id="content-wrapper">
    <nav-bar />
    <div v-if="newuser && !isDashboard" class="container">
      <div class="alert alert-warning" role="alert">
        Lembre-se de
        <strong>confirmar o seu email</strong> para ter acesso liberado para colaborar com o Genealogica Bíblica.
        <hr/>
        <confirm-email-button />
      </div>
    </div>
    <router-view />

    </div>
  </div>
</template>
<script>
import ConfirmEmailButton from "@/components/ConfirmEmailButton";
import NavBar from "@/components/NavBar.vue";
import Overlay from "@/components/Overlay.vue";
import AdminNavBar from "@/components/AdminNavBar.vue";
import http from '@/http'

export default {
  name: "Home",
  components: {
    NavBar,
    Overlay,
    ConfirmEmailButton,
    AdminNavBar
  },

  computed: {
    newuser() {
      return this.$store.state.user.status === 0;
    },
    isDashboard() {
      return this.$route.name === 'dashboard'
    },
    admin() {
      return this.$store.state.user.role === "admin";
    }
  },

  mounted() {
    this.$store.dispatch("showOverlay", false);
    this.$store.dispatch("listaLivrosComEsboco");

    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  created() {
    http.interceptors.response.use(undefined, err => {
      return new Promise(() => {
        if (err.response.status === 403 && err.config && !err.config.__isRetryRequest) {

          this.$store.dispatch('showOverlay',false)
          this.$store.dispatch('logout')
        }
        throw err;
      });
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.$store.dispatch("showOverlay", false);
  },

  methods: {
    onResize() {
      this.$store.dispatch("resizeWindow", {
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
  }
};
</script>
<style scoped>
#wrapper {
  height: 100%;
}
</style>