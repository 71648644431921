import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/timeline/:id?',
    name: 'Timeline',
    component: () => import(/* webpackChunkName: "about" */ '../views/Timeline.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/search/:search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
  },
  {
    path: '/arvore/:id',
    name: 'Arvore',
    component: () => import(/* webpackChunkName: "about" */ '../views/Arvore.vue')
  },
  {
    path: '/biblia/:book?/:chapter?/:versicle?',
    name: 'Biblia',
    component: () => import(/* webpackChunkName: "about" */ '../views/Biblia.vue')
  },
  {
    path: '/arvore-dinamica/:id',
    name: 'ArvoreDinamica',
    component: () => import(/* webpackChunkName: "about" */ '../views/ArvoreDinamica.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "about" */ '../views/Forgot.vue')
  },
  {
    path: '/dashboard/:id?',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/esbocos',
    name: 'esbocos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Esbocos.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/esboco/:liv',
    name: 'esboco',
    component: () => import(/* webpackChunkName: "about" */ '../views/Esboco.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/events/:id?',
    name: 'events',
    component: () => import(/* webpackChunkName: "about" */ '../views/EventsDashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/text/:id?',
    name: 'text',
    component: () => import(/* webpackChunkName: "about" */ '../views/TextDashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/events-edit/:id?',
    name: 'events-edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/EditEvent.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/text-edit/:id?',
    name: 'text-edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/EditText.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit/:id?',
    name: 'edit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Edit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/:id?',
    name: 'profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/edit/:id?',
    name: 'editProfile',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import(/* webpackChunkName: "about" */ '../views/Confirm.vue'),
  },
  {
    path: '/emails',
    name: 'emails',
    component: () => import(/* webpackChunkName: "about" */ '../views/Emails.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChangePassword.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reset/:token',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChangePassword.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})

export default router
