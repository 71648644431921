export default {
    overlay: false,
    user: {},
    logged: false,
    windowSize: {},
    mobile: false,
    listaPessoas: [],
    livros: [],
    temas: [],
    esbocos: [],
    adminNavBar: false
}