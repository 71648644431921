<template>
    <div v-if='show' class="overlay">
        <div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
    </div>
</template>
<script>
export default {

    computed: {
        show() {
            return this.$store.state.overlay
        }
    }
    
}
</script>
<style scoped>
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.7);
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #616161;
        font-size: 50px;
    }

.spinner-border {
    display: inline-block;
    width: 10rem;
    height: 10rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .55s linear infinite;
    animation: spinner-border .55s linear infinite;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}
</style>