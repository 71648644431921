import http from '@/http'
import { montaUrl } from '@/util/url-util'

export function getPessoas(filters) {
    let url = montaUrl(filters)
    return http.get(`/api/people?${url}`)
}

export function getOne(id) {
    return http.get(`/api/people/${id}`)
}

export function getDynamic(id) {
    return http.get(`/api/v1/people/dynamic/${id}`)
}

export function list() {
    return http.get(`/api/people/list`)
}

export function add(data) {
    return http.post('/api/people',data)
}
export function edit(id,data) {
    return http.put(`/api/people/${id}`,data)
}

export default {edit}