import axios from 'axios'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
})

http.interceptors.request.use(config => {
  config.headers = {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Accept': 'application/json'
  }
  return config
}, error => {

  // eslint-disable-next-line no-debugger
  console.log(error.response)

  return Promise.reject(error)
})

export default http