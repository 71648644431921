import http from '@/http'
import { montaUrl } from '@/util/url-util'

export function login(data) {
    return http.post('/api/auth/login',data)
}

export function confirm(data, token) {
    localStorage.setItem('token',token)
    return http.post(`/api/auth/confirm`,data)
}

export function resend(data) {
    return http.post(`/api/auth/resend`,data)
}

export function registrar(filter, data) {
    const url = montaUrl(filter)
    return http.post(`/api/auth/register?${url}`,data)
}

export function changePassword(data) {
    return http.post("/api/v1/auth/password",data)
}

export function resetPassword(token, data) {
    return http.post(`/api/v1/auth/reset?token=${token}`,data)
}

export function forgotPassword(data) {
    return http.post(`/api/v1/auth/forgot`,data)
}

export default {confirm, registrar, resetPassword}