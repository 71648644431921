import http from '@/http'
import { montaUrl } from '@/util/url-util'

export function getBiblia(filters, book, chapter, versicle) {
    let url = montaUrl(filters)
    if (versicle) {
        return http.get(`/api/versicles/ara/${book}/${chapter}/${versicle}?${url}`)
    }
    return http.get(`/api/versicles/ara/${book}/${chapter}?${url}`)
}

export function listBooks() {
    return http.get(`/api/versicles/books`)
}

export function listChapters(book) {
    return http.get(`/api/versicles/chapters/ara/${book}`)
}

export function listVersicles(book, chapter) {
    return http.get(`/api/versicles/versicles/ara/${book}/${chapter}`)
}

export function getOne(id) {
    return http.get(`/api/versicles/${id}`)
}

export default {listVersicles}