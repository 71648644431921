import Vue from "vue"
import { differenceInYears, parse, format, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

Vue.filter("idade",value => {

    if(value == undefined) {
        return undefined;
    }

    let dateNoTime = value.split("T")[0];

    const nascimento = parse(dateNoTime,'yyyy-MM-dd',new Date())
    
    return differenceInYears(new Date(), nascimento)
})

Vue.filter("cep", value => {

    if(value == undefined || value.length !== 8) {
        return value;
    }

    return `${value.substring(0,5)}-${value.substring(5,8)}`
})

Vue.filter('month-name', value => {
    let date = parse(value,'M',new Date());

    return format(date,'MMMM',{locale : ptBR})
})

Vue.filter('date', value => {

    if(!value) {
        return null
    }
    const date = parseISO(value)

    return format(date, 'dd/MM/yyyy')
})

Vue.filter('era', value => {
    if(value > 0) {
        return `${value} d.C.`
    }
    return `${value * -1} a.C.`
})


Vue.filter('datetime', value => {

    if(!value) {
        return null
    }
    const date = parseISO(value)

    return format(date, 'dd/MM/yyyy HH:mm')
})

Vue.filter('leftPad', value => {
    let str = value + ""
    let pad = "00"
    return pad.substring(0, pad.length - str.length) + str
})

Vue.filter('reais', value => {

    if(!value) {
        return null;
    }

    return `R$ ${value.replace('.',',')}`

})

Vue.filter('nomeLivro', value => {

    return {
        'gn': 'Gênesis',
        'ex': 'Êxodo',
        'lv': 'Levítico',
        'nm': 'Números',
        'dt': 'Deuteronômio',
        'js': 'Josué',
        'jz': 'Juízes',
        'rt': 'Rute',
        '1sm': 'I Samuel',
        '2sm': 'II Samuel',
        '1rs': 'I Reis',
        '2rs': 'II Reis',
        '1cr': 'I Crônicas',
        '2cr': 'II Crônicas',
        'ed': 'Esdras',
        'ne': 'Neemias',
        'et': 'Ester',
        'jo': 'Jó',
        'sl': 'Salmos',
        'pv': 'Provérbios',
        'ec': 'Eclesiastes',
        'ct': 'Cântico dos Cânticos',
        'is': 'Isaías',
        'jr': 'Jeremias',
        'lm': 'Lamentações de Jeremias',
        'ez': 'Ezequiel',
        'dn': 'Daniel',
        'os': 'Oseias',
        'jl': 'Joel',
        'am': 'Amós',
        'ob': 'Obadias',
        'jn': 'Jonas',
        'mq': 'Miqueias',
        'na': 'Naum',
        'hc': 'Habacuque',
        'sf': 'Sofonias',
        'ag': 'Ageu',
        'zc': 'Zacarias',
        'ml': 'Malaquias',
        'mt': 'Mateus',
        'mc': 'Marcos',
        'lc': 'Lucas',
        'joao': 'João',
        'at': 'Atos',
        'atos': 'Atos',
        'rm': 'Romanos',
        '1co': 'I Coríntios',
        '2co': 'II Coríntios',
        'gl': 'Gálatas',
        'ef': 'Efésios',
        'fp': 'Filipenses',
        'cl': 'Colossenses',
        '1ts': 'I Tessalonicenses',
        '2ts': 'II Tessalonicenses',
        '1tm': 'I Timóteo',
        '2tm': 'II Timóteo',
        'tt': 'Tito',
        'fm': 'Filemom',
        'hb': 'Hebreus',
        'tg': 'Tiago',
        '1pe': 'I Pedro',
        '2pe': 'II Pedro',
        '1jo': 'I João',
        '2jo': 'II João',
        '3jo': 'III João',
        'jd': 'Judas',
        'ap': 'Apocalipse'
      }[value]
})