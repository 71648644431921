export default {
    overlay(state, value) {
        state.overlay = value
    },
    user(state, value) {
        state.user = value.user
        state.token = value.token
        state.logged = true
    },
    profile(state, value) {
        state.user = value
    },
    logout(state) {
        state.user = {}
        delete state.token
        state.logged = false
        state.listaPessoas = []
    },
    resizeWindow(state, value) {
        state.windowSize = value
        state.mobile = value.width < 992
    },
    listaPessoas(state, value) {
        state.listaPessoas = value.filter(p => p)
    },
    livros(state, value) {
        state.livros = value
    },
    temas(state, value) {
        state.temas = value
    },
    esbocos(state, value) {
        state.esbocos = value
    },
    adminNavBar(state) {
        state.adminNavBar = !state.adminNavBar
    }
}