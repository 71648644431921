<template>
  <div class="home container">
    <div class="row align-items-center">
      <div class="col">
        <h1>Genealogia Bíblica</h1>
        <form
          @submit.stop.prevent="search"
          class="d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
        >
          <div class="input-group">
            <input
              type="text"
              class="form-control form-control-lg bg-light border-0"
              placeholder="Nome..."
              aria-label="Busca"
              aria-describedby="basic-addon2"
              v-model="nome"
            />
            <div class="input-group-append">
              <button class="btn btn-primary" type="submit">
                <i class="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form>
        

      <div class="mt-3">
        Exemplos:
        <router-link :to="{ name: 'Search', params: { search: 'Abraão'} }">
          <span class="badge badge-primary">Abraão</span>
        </router-link>
        <router-link class='ml-2' :to="{ name: 'Search', params: { search: 'Adão'} }">
          <span class="badge badge-primary">Adão</span>
        </router-link>
        <router-link class='ml-2' :to="{ name: 'Search', params: { search: 'Davi'} }">
          <span class="badge badge-primary">Davi</span>
        </router-link>
        <router-link class='ml-2' :to="{ name: 'Search', params: { search: 'Jesus'} }">
          <span class="badge badge-primary">Jesus</span>
        </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nome: null
    };
  },
  methods: {
    search() {
      this.$router.push({ name: "Search", params: { search: this.nome } });
    }
  }
};
</script>
<style scoped>
.container {
  margin-top: 10%;
  max-width: 420px;
}


</style>