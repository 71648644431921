import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'genealogiabiblica',
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  getters,
  state,
  mutations,
  actions,
  modules: {
  }
})
