import { jwtDecode } from "jwt-decode";
import { fromUnixTime, isBefore } from 'date-fns'

export default {
  isLoggedIn: (state) => {
    if (!state.token) {
      return false
    }
    const decoded = jwtDecode(state.token);

    const exp = fromUnixTime(decoded.exp)

    return isBefore(new Date(), exp)
  },
  mulheres: state => {
    return state.listaPessoas.filter(p => p.sexo === 0)
  },
  homens: state => { 
    return state.listaPessoas.filter(p => p.sexo === 1)
  },
  admin: state => {
    return state.user.role === "admin"
  },
  viewer: state => {
    return state.user.role === "viewer"
  },
  logged: state => state.logged
};
