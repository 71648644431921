<template>
    <img class='profile-picture-limit-size' :src="require(`@/assets/${picture || 'profile'}.png`)" />
</template>
<script>
export default {

    props: {
        picture: String
    }
}
</script>
<style scoped>
.profile-picture-limit-size {
    width: 100%;
    max-width: 256px;
}
</style>