import http from '@/http'

export function getOne(id) {
    return http.get(`/api/text/${id}`)
}

export function add(data) {
    return http.post('/api/text',data)
}

export function edit(id,value) {
    return http.put(`/api/text/${id}`,value)
}

export function get(liv) {
    return http.get(`/api/text/${liv}`)
}

export function list() {
    return http.get(`/api/text/list`)
}