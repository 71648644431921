import { login } from '@/services/auth-service'
import { list } from '@/services/pessoas-service'
import { listBooks } from '@/services/biblia-service'
import { list as listEsbocos} from '@/services/text-service'
import { getAll } from '@/services/temas-service'

export default {

    showOverlay({ commit }, value) {
        commit('overlay', value)
    },

    login({ commit }, value) {

        return new Promise((resolve, reject) => {

            login(value).then(({ data }) => {
                commit('user', data)

                localStorage.setItem('token', data.token)
                resolve(data)
            }).catch(reject)
        })
    },

    toggleAdminNavBar({commit}) {
        commit('adminNavBar')
    },

    register({commit}, value) {
        commit('user', value)
        localStorage.setItem('token', value.token)
    },

    updateProfile({ commit }, data) {
        commit('profile', data)
    },

    listaPessoas({ commit }) {

        return new Promise((resolve, reject) => {
            list().then(({ data }) => {
                commit('listaPessoas', data)
                resolve()
            }).catch(reject)
        })
    },

    logout({ commit }) {

        return new Promise(resolve => {
            localStorage.removeItem('token')
            commit('logout')
            resolve()
        })
    },

    resizeWindow({ commit }, value) {
        commit('resizeWindow', value)
    },

    listaLivros({ commit }) {

        return new Promise((resolve, reject) => {
            listBooks().then(({ data }) => {
                commit('livros', data)
                resolve()
            }).catch(reject)
        })
    },

    listaLivrosComEsboco({ commit }) {

        return new Promise((resolve, reject) => {
            listEsbocos().then(({ data }) => {
                commit('esbocos', data)
                resolve()
            }).catch(reject)
        })
    },

    listaTemas({ commit }) {

        return new Promise((resolve, reject) => {
            getAll().then(({ data }) => {
                commit('temas', data)
                resolve()
            }).catch(reject)
        })
    }

}