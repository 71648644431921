import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/util/filters'

import 'alertifyjs/build/alertify.min.js'
import 'alertifyjs/build/css/alertify.min.css'
import 'alertifyjs/build/css/themes/default.min.css'
import Alertifyjs from 'vue2-alertifyjs'
import Vuelidate from 'vuelidate'

const opts = {
  notifier:{
    delay:5,
    position:'bottom-right',
    closeButton: false
  }
}

Vue.use(Vuelidate)
Vue.use(Alertifyjs,opts)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
