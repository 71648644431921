<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <button @click='toggleAdminNavBar' v-if='admin' id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
        <i class="fa fa-bars"></i>
    </button>
    <a class="navbar-brand" href="/">Genealogia Bíblica</a>

    <form-search class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"/>

    <ul class="navbar-nav ml-auto">
      <!-- Nav Item - Search Dropdown (Visible Only XS) -->
      <li class="nav-item dropdown no-arrow d-sm-none show">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="searchDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          @click.stop="openForm"
          :aria-expanded="showMobileSearch"
        >
          <i class="fas fa-search fa-fw"></i>
        </a>
        <div
          @click.stop="doNothing"
          class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
          :class="{ 'show' : showMobileSearch }"
          aria-labelledby="searchDropdown"
        >
        <form-search class="form-inline mr-auto w-100 navbar-search"/>
        </div>
      </li>
      <div class="topbar-divider d-none d-sm-block"></div>
      <li class="nav-item dropdown no-arrow">
        <router-link title="Esboços Bíblicos" class="nav-link" :to="{ name: 'esbocos'}">
          <i class="fas fa-book fa-sm fa-fw text-gray-400"></i>
          <span class="ml-1 mr-2 d-none d-lg-inline text-gray-600 small">Esboços Bíblicos</span>
        </router-link>
      </li>
      <div class="topbar-divider d-none d-sm-block"></div>
      <li class="nav-item dropdown no-arrow">
        <router-link title="Linha do Tempo" class="nav-link" :to="{ name: 'Timeline'}">
          <i class="fas fa-clock fa-sm fa-fw text-gray-400"></i>
          <span class="ml-1 mr-2 d-none d-lg-inline text-gray-600 small">Linha do Tempo</span>
        </router-link>
      </li>
      <div class="topbar-divider d-none d-sm-block"></div>
      <li class="nav-item dropdown no-arrow" :class="{'show'  : showProfileMenu}" v-if="logged">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          :aria-expanded="showProfileMenu"
          @click.stop="openMenu"
        >
          <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{userName}}</span>
          <profile-picture class="img-profile rounded-circle" :picture='user.picture' />
        </a>
        <div
          class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          :class="{'show'  : showProfileMenu}"
          aria-labelledby="userDropdown"
        >
          <router-link :to="{ name : 'profile'}" class="dropdown-item">
            <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
            Perfil
          </router-link>
          <router-link :to="{name : 'dashboard'}" class="dropdown-item">
            <i class="fas fa-code-branch fa-sm fa-fw mr-2 text-gray-400"></i>
            Versões
          </router-link>
          <router-link :to="{name : 'events'}" class="dropdown-item">
            <i class="fas fa-code-branch fa-sm fa-fw mr-2 text-gray-400"></i>
            Versões de Eventos
          </router-link>
          <router-link :to="{name : 'text'}" class="dropdown-item">
            <i class="fas fa-code-branch fa-sm fa-fw mr-2 text-gray-400"></i>
            Versões de Passagens
          </router-link>
          <router-link v-if="!viewer" :to="{name : 'edit'}" class="dropdown-item">
            <i class="fas fa-plus fa-sm fa-fw mr-2 text-gray-400"></i>
            Adicionar Pessoa
          </router-link>
          <router-link v-if="!viewer" :to="{name : 'events-edit'}" class="dropdown-item">
            <i class="fas fa-plus fa-sm fa-fw mr-2 text-gray-400"></i>
            Adicionar Evento
          </router-link>
          <router-link v-if="!viewer" :to="{name : 'text-edit'}" class="dropdown-item">
            <i class="fas fa-plus fa-sm fa-fw mr-2 text-gray-400"></i>
            Adicionar Passagem
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="#" @click.stop.prevent="logout()" class="dropdown-item">
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Logout
          </a>
        </div>
      </li>
      <li class="nav-item dropdown no-arrow" v-else>
        <router-link title="login" class="nav-link" :to="{ name: 'login'}">
          <i class="fas fa-sign-in-alt fa-sm fa-fw text-gray-400"></i>
          <span class="ml-1 mr-2 d-none d-lg-inline text-gray-600 small">Login</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import FormSearch from "@/components/FormSearch";
import ProfilePicture from "@/components/ProfilePicture";
export default {
  components: {
    FormSearch,
    ProfilePicture
  },
  data() {
    return {
      showMobileSearch: false,
      showProfileMenu: false
    };
  },
  computed: {
    admin() {
      return this.$store.state.user.role === "admin";
    },
    logged() {
      return this.$store.state.logged;
    },
    user() {
      return this.$store.state.user
    },
    userName() {
      return this.$store.state.user.name;
    },
    viewer() {
      return this.$store.state.user.role === "viewer";
    }
  },
  methods: {
    toggleAdminNavBar() {
      this.$store.dispatch('toggleAdminNavBar')
    },
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push({ name: "Home" });
    },

    doNothing() {},

    openMenu() {
      if (this.showProfileMenu) {
        this.closeForm();
        return;
      }
      this.showProfileMenu = true;
      document.addEventListener("click", this.closeForm);
    },

    openForm() {
      if (this.showMobileSearch) {
        this.closeForm();
        return;
      }
      this.showMobileSearch = true;
      document.addEventListener("click", this.closeForm);
    },

    closeForm(e) {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      this.showMobileSearch = false;
      this.showProfileMenu = false;
      document.removeEventListener("click", this.closeForm);
    }
  },

  beforeDestroy() {
    document.removeEventListener("click", this.closeForm);
  }
};
</script>