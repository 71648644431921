<template>
  <form
    @submit.stop.prevent="search"
    
  >
    <div class="input-group">
      <input
        type="text"
        class="form-control bg-light border-0 small"
        placeholder="Nome..."
        aria-label="Busca"
        aria-describedby="basic-addon2"
        v-model="nome"
      />
      <div class="input-group-append">
        <button class="btn btn-primary" type="submit">
          <i class="fas fa-search fa-sm"></i>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  data() {
    return {
      nome: undefined
    };
  },
  methods: {
    search() {
      this.$router.push({ name: "Search", params: { search: this.nome } });
    }
  }
};
</script>