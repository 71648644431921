<template>
  <button @click="resend" class="btn btn-primary">
    <i class="far fa-envelope"></i> Reenviar Email
  </button>
</template>
<script>
import { resend } from "@/services/auth-service";
export default {
  props: {
    logout: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async resend() {

      const id = this.$store.state?.user?.id || this.$route.query.id;

      this.$store.dispatch("showOverlay", true);

      try {
        await resend({id});
        this.$notify("Email reenviado!", "success");
      } catch (error) {
        const message = ((error || { response: {} }).response || { data: {} })
          .data.message;
        this.$notify(`Erro ao enviar email: ${message || ""}`, "error");
      } finally {
        if (this.logout) {
          this.$store.dispatch("logout");
        }
        this.$store.dispatch("showOverlay", false);
      }
    }
  }
};
</script>